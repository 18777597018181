<template>
  <div class="memu-wrap">
    <router-link v-for="item in menuList" :key="item.name" class="memu-wrap-item" :to="item.name">
      <span
        v-if="!item.isHide"
        :class="currentPathName ===item.name? 'is-active' : ''"
        @click="checkName(item.name)"
      >
        {{ item.meta.title }}
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [],
      currentPathName: ''
    }
  },
  created() {
    const mainRoutes = this.$router.options.routes.find(item => item.name === 'main').children

    console.log('mainRoutes', mainRoutes)

    const route = this.$route.matched
    this.currentPathName = route[route.length - 1].name
    console.log(route[route.length - 1])
    let routes = []
    for (let i = 0; i < route.length - 1; i++) {
      routes = mainRoutes.find(e => {
        return e.name === route[i].name
      })
    }
    this.menuList = routes.children
  },
  methods: {
    checkName(name) {
      this.currentPathName = name
    }
  }
}
</script>

<style lang="less" scoped>
.memu-wrap {
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &-item {
    color: #000;
    span {
      margin-right: 20px;
      padding: 0 25px;
      line-height: 40px;
      display: block;
    }
    & .is-active {
      background: rgba(18, 110, 255, 1);
      border-radius: 4px;
      color: #ffffff;
    }
  }
}
</style>
